/*
 * @Date: 2023-02-12 13:17:26
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-12 13:56:30
 * @FilePath: \F1-M2-QRP-CODE\components\hayhar\banner.js
 */
import React from "react";
import { Carousel } from 'antd';

import HostConfig from '$CONFIG/Host.config';
import LazyLoad from 'react-lazyload';
const CMSURL = HostConfig.Config.CMSURL;


class Banner extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			index : 0,
			pageWidth:0,
		}
		this.Banner = React.createRef();
		this.resize = this.resize.bind(this);
		this.afterChange = this.afterChange.bind(this);
	}
	
	componentDidMount(){
		// this.getBannerList();
		this.resize();
		this.screenChange();
		
	}

	componentWillUnmount(){
		window.removeEventListener('resize',this.resize);
	}
	
	
	resize(){
		this.setState({
			pageWidth : document.body.clientWidth || 1024,
		})
	}

	/**
	 * 监听屏幕尺寸变化
	 */
	screenChange(){
		window.addEventListener('resize',this.resize);
		
	}
	
	next() {
		this.Banner.current.next();
	}
	prev() {
		this.Banner.current.prev();
	}
	
	goIndex(index) {
		this.Banner.current.goTo(index,true);
	}
	
	/**
	 * @param {Object} current
	 */
	afterChange(current) {
		this.props.setCurBannerIdx && this.props.setCurBannerIdx(current);
	}
	
	getBannerList(){
		
	}
	
	render(){
		const { pageWidth } = this.state;
		const { bannerList } = this.props;
		return (
			<>
				<Carousel
					ref={this.Banner}
					afterChange={this.afterChange}
					autoplay={true}
					autoplaySpeed={2500}
					lazyLoad={true}
					dots={false}
				>
					{bannerList && bannerList.length ?
						bannerList.map((item, index) => {
							return(
								<LazyLoad key={index}>
									<div className="img_wrap">
										<img
											src={`${pageWidth > 1023 ? item.cmsImageUrlWeb : item.cmsImageUrlMobile}`}
											className={'imgItem'}
											// onClick={() => this.props.bannerClick(bannerFlag ? item : bannerList[0])}
											alt={`${pageWidth > 1023 ? item.cmsImageUrlWebAlt : item.cmsImageUrlMobileAlt}`}
										/>
									</div>
								</LazyLoad>
							)
						})
					: null}
				</Carousel>
			</>
		
		)
		
	}
	
}

export default Banner;
